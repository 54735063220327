import { GetCurrentYearDiyJobResponse } from '@taxfyle/api-internal/internal/diy_pb'
import { timestampToISO, decimalToNumber } from 'utils/grpcUtil'

export function mapGrpcDiyJob(diyJob) {
  if (!diyJob) {
    return null
  }
  return {
    id: diyJob.diyJobId,
    taxYear: diyJob.diyJobTaxYear,
    price: diyJob.price ? decimalToNumber(diyJob.price) : undefined,
    consultationJobId: diyJob.consultationJobId?.value,
    dateCreated: timestampToISO(diyJob.dateCreated),
    dateUrlLastRequested: timestampToISO(diyJob.dateUrlLastRequested),
    federalSubmissionStatus: mapGrpcFederalSubmissionStatus(
      diyJob.federalSubmissionStatus
    ),
  }
}

function mapGrpcFederalSubmissionStatus(federalSubmissionStatus) {
  switch (federalSubmissionStatus) {
    case GetCurrentYearDiyJobResponse.DiyJob.JurisdictionSubmissionStatus
      .JURISDICTION_SUBMISSION_STATUS_UNSPECIFIED:
      return 'IN_PROGRESS'
    case GetCurrentYearDiyJobResponse.DiyJob.JurisdictionSubmissionStatus
      .NOT_SUBMITTED:
      return 'NOT_SUBMITTED'
    case GetCurrentYearDiyJobResponse.DiyJob.JurisdictionSubmissionStatus
      .SUBMITTED:
      return 'SUBMITTED'
    case GetCurrentYearDiyJobResponse.DiyJob.JurisdictionSubmissionStatus
      .ACCEPTED:
      return 'ACCEPTED'
    case GetCurrentYearDiyJobResponse.DiyJob.JurisdictionSubmissionStatus
      .RETRYABLE:
      return 'RETRYABLE'
    case GetCurrentYearDiyJobResponse.DiyJob.JurisdictionSubmissionStatus
      .REJECTED:
      return 'REJECTED'
    default:
      return 'IN_PROGRESS'
  }
}
