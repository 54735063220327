import { Store } from 'libx'
import { observable, runInAction, computed, action } from 'mobx'
import { extractMessageFromError } from 'utils/errorUtil'
import { browserHistory } from 'react-router'
import { task } from 'mobx-task'
import links from 'misc/links'
import QS from 'qs'

export default class DiyStore extends Store {
  @observable
  columnTaxUrl = ''

  @observable
  diyJobId = ''

  @observable
  consultationJob = null

  @observable
  qs

  @observable
  sidePanelOpen = false

  constructor({ rootStore }) {
    super({ rootStore })
  }

  @computed
  get consultation() {
    return this.consultationJob?.consultation
  }

  @computed
  get consultationChampionPro() {
    if (!this.consultationJob) {
      return null
    }

    return this.consultationJob.members.find(
      (m) => m.role === 'CHAMPION' && m.type === 'PROVIDER'
    )
  }

  @computed
  get allowsConsultationUpsell() {
    return (
      !!this.rootStore.sessionStore.workspace.config.diy
        ?.upsell_consultations &&
      this.rootStore.sessionStore.member.hasPermission('JOB_CREATE')
    )
  }

  @computed get consultationLegendId() {
    const workspaceConfig = this.rootStore.sessionStore.workspace.config
    return workspaceConfig.diy
      ? workspaceConfig.diy.consultation_legend_id
      : null
  }

  @computed
  get hideNavbar() {
    return this.qs?.hideNavbar === 'true'
  }

  @computed
  get headerProps() {
    const { simplifiedNavBar } =
      this.rootStore.sessionStore.workspace.features.jobDetailsConfig
    return (
      simplifiedNavBar.enabled && {
        simplified: true,
        simplifiedLogo: simplifiedNavBar.image,
        backTargetUrl: simplifiedNavBar.targetUrl,
      }
    )
  }

  @computed
  get hasSelectedTimes() {
    return this.rootStore.projectDetailsStore.scheduleStore.hasSelectedTimes
  }

  @computed
  get openSidePanelButtonText() {
    if (!this.consultationJob) {
      return 'Upgrade to Assisted'
    }
    switch (this.consultationJob.status) {
      case 'UNDER_CONSTRUCTION':
        return 'Upgrade to Assisted'
      case 'INFO_GATHERING':
        return 'Set your availability'
      case 'UNCLAIMED':
        return 'View call details'
      case 'CLAIMED':
        return 'View call details'
      case 'ON_HOLD':
        return 'View call details'
      case 'IDLE':
        return 'View call details'
      case 'CLOSED':
        return 'Call summary'
      default:
        return 'Call summary'
    }
  }

  @action.bound
  openConsultationSidePanel() {
    this.sidePanelOpen = true
  }

  @action.bound
  closeConsultationSidePanel() {
    this.sidePanelOpen = false
  }

  @action.bound
  showConfirmScheduleDialog = async () =>
    await this.rootStore.projectDetailsStore.confirmScheduleDialogStore.show()

  redirectToDashboard(event) {
    if (typeof event.data === 'string') {
      const diyEventData = JSON.parse(event.data)

      if (diyEventData?.name !== 'column-on-close') {
        return
      }

      browserHistory.push(links.projects)
    }
  }

  @task
  async activate() {
    runInAction(() => {
      this.qs = QS.parse(location.search, { ignoreQueryPrefix: true })
    })

    const workspaceId = this.rootStore.sessionStore.workspace.id
    await this.rootStore.api.diy
      .createDiyUrl({ workspaceId })
      .then(async (res) => {
        runInAction(() => {
          this.columnTaxUrl = res.url
          this.diyJobId = res.diyJobId
        })

        // If there's no consultationJob linked, and we have a consultationJobId in the query string, link it.
        if (!res.consultationJobId && this.qs?.consultationJobId) {
          try {
            await this.rootStore.projectStore.linkJobToDiy(
              this.qs.consultationJobId,
              this.diyJobId
            )
          } catch (err) {
            this.rootStore.flashMessageStore
              .create(extractMessageFromError(err))
              .failed()
          }
        }

        const consultationJobId =
          res.consultationJobId?.value || this.qs?.consultationJobId

        if (consultationJobId) {
          await this.rootStore.projectDetailsStore.activate(
            consultationJobId,
            true
          )

          const project = this.rootStore.projectDetailsStore.project
          if (project) {
            runInAction(() => {
              this.consultationJob = project
            })
          }
        }
      })
      .catch((err) => {
        this.rootStore.flashMessageStore
          .create(extractMessageFromError(err))
          .failed()
        runInAction(() => {
          browserHistory.replace(links.projects)
        })
      })
  }

  goToConsultationJob = task.resolved(async () => {
    if (!this.allowsConsultationUpsell) {
      return
    }

    if (this.consultationJob) {
      if (this.consultationJob.status === 'UNDER_CONSTRUCTION') {
        runInAction(() => {
          browserHistory.push(
            links.projectEditor(
              this.consultationJob.id,
              this.qs?.hideNavbar,
              true
            )
          )
        })
      } else {
        runInAction(() => {
          browserHistory.push(
            links.projectDetails(this.consultationJob.id, this.qs?.hideNavbar)
          )
        })
      }
      return
    }

    const latestConsultationLegendVersion =
      await this.rootStore.api.legends.getVersion(this.consultationLegendId)

    const consultationJobId = await this.rootStore.draftStore.createDraftJob(
      this.consultationLegendId,
      latestConsultationLegendVersion.version
    )

    try {
      this.rootStore.api.diy.linkConsultationJobToDiyJob({
        diyJobId: this.diyJobId,
        consultationJobId: consultationJobId,
      })
    } catch (err) {
      this.rootStore.flashMessageStore
        .create(extractMessageFromError(err))
        .failed()
      return
    }

    runInAction(() => {
      browserHistory.push(
        links.projectEditor(consultationJobId, this.qs?.hideNavbar, true)
      )
    })
  })

  goToVideoCall = task.resolved(async () => {
    if (!this.consultation.roomId) {
      return
    }

    runInAction(() => {
      browserHistory.push(
        links.projectRoom(
          this.consultationJob.id,
          this.consultation.roomId,
          this.qs?.hideNavbar
        )
      )
    })
  })
}
