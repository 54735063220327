import decodeJwt from 'jwt-decode'

export default class FlutterAuthClient {
  constructor() {
    this.token = null
  }

  /**
   * Returns the token upon successful retrieval.
   *
   * @returns {String}
   */
  async getToken() {
    if (this.token) {
      const currentTimestamp = Math.floor(Date.now() / 1000)
      const decoded = decodeJwt(this.token)

      if (decoded.exp - 30 > currentTimestamp) {
        return this.token
      }
    }

    try {
      const token = await window.flutter_inappwebview.callHandler('getToken')
      this.token = token || null
      return this.token
    } catch (error) {
      console.error('Error retrieving token from Flutter:', error)
      return null
    }
  }

  /**
   * Returns the session from the successful retrieval of the token.
   *
   * @returns {Object}
   */
  async fetchSessionInfo() {
    const token = await this.getToken()
    const decoded = decodeJwt(token)

    return { token, authId: decoded.sub, claims: [] }
  }

  /**
   * Redirects to our retry handler.
   *
   * @returns {Object}
   */
  logout() {
    return window.location.replace('/login?error=invalid_token')
  }

  /**
   * Handles redirect after exchanging the authorization code.
   *
   * @returns {Object}
   */
  async handleRedirect() {
    console.log('Ignoring redirect for FlutterAuthClient')
  }
}

/**
 * Creates a FlutterAuthClient object when the value of window.flutter_inappwebview
 * is defined or the refresh token is set in the session storage.
 *
 * @returns {FlutterAuthClient | null}
 */
export function createFlutterAuthClient() {
  const flutterSession = window.flutter_inappwebview
  return flutterSession ? new FlutterAuthClient() : null
}
