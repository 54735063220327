import { Store } from 'libx'
import { observable, computed, action, runInAction } from 'mobx'

export default class ScopeChangeRequestResponseDialogStore extends Store {
  @observable showing = false
  @observable scopeChangeRequest = null
  @observable breakdown = null

  constructor() {
    super(...arguments)
    this.accept = this.accept.bind(this)
    this.decline = this.decline.bind(this)
  }

  /**
   * Gets the current user public id.
   */
  @computed
  get currentUserPublicId() {
    return this.rootStore.sessionStore.user.public_id
  }

  @action.bound
  reset() {
    this.scopeChangeRequest = null
    this.breakdown = null
  }

  @action.bound
  async show(scopeChangeRequest) {
    this.reset()
    await this.fetchScopeChangeRequestBreakdown(scopeChangeRequest.id)
    runInAction(() => {
      this.scopeChangeRequest = scopeChangeRequest
      if (scopeChangeRequest != null) {
        this.showing = true
      }
    })
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @action.bound
  async onAccept() {
    await this.rootStore.scopeChangeRequestStore.approveScopeChangeRequest(
      this.scopeChangeRequest.jobId
    )
    this.hide()
    this.rootStore.scopeChangeRequestResponseConfirmationDialogStore.show(
      this.scopeChangeRequest,
      'accept'
    )

    await this.rootStore.projectDetailsStore.activate(
      this.rootStore.projectDetailsStore.project.id
    )
  }

  async accept() {
    if (
      this.rootStore.scheduleStore.isPhoneCall &&
      this.scopeChangeRequest.items.some(
        (i) => i.isConsultation && i.quantity > 0
      )
    ) {
      this.rootStore.confirmPhoneDialogStore.show(this.onAccept)
      this.hide()
      return
    }

    await this.onAccept()
  }

  decline() {
    this.hide()
    this.rootStore.scopeChangeRequestResponseConfirmationDialogStore.show(
      this.scopeChangeRequest,
      'decline'
    )
  }

  @action.bound
  async fetchScopeChangeRequestBreakdown(scopeChangeRequestId) {
    const breakdown =
      await this.rootStore.scopeChangeRequestBreakdownStore.fetchScopeChangeRequestBreakdown(
        scopeChangeRequestId
      )
    runInAction(() => {
      this.breakdown = breakdown
    })
  }
}
