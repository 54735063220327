import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, required } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'
import { validateObject } from 'src/client/utils/validx-validators'
import { obscureSsn } from 'src/client/utils/stringUtil'
import { PersonalInfoViewModel } from '../PersonalInfoViewModel'

/**
 * View model for managing the document supporting item input.
 */
export class SpousalInfoSupportingItemInputViewModel {
  /**
   * The underlying item.
   *
   * @type {RequestedSupportingItem}
   */
  @observable item

  @observable showingDialog = false
  @observable personalInfo = new PersonalInfoViewModel()
  @observable occupation

  validation = validationContext(this, {
    personalInfo: [validateObject('Personal information is required')],
    occupation: [required('Occupation is required')],
  })

  constructor({
    item,
    flashMessageStore,
    individualProfileStore,
    infoGatheringStore,
    getCurrentJob,
  }) {
    /**
     * @type {RequestedSupportingItem}
     */
    this.item = item
    this.flashMessageStore = flashMessageStore
    this.individualProfileStore = individualProfileStore
    this.infoGatheringStore = infoGatheringStore
    this.getCurrentJob = getCurrentJob
  }

  activate = task.resolved(async () => {
    await this.fetchSpousalProfile()
  })

  /**
   * The current job.
   */
  @computed
  get currentJob() {
    return this.getCurrentJob()
  }

  /**
   * The current profile for the job if any
   */
  @computed
  get profile() {
    return this.individualProfileStore.getSpousalProfileVersion(
      this.item.input.profileId,
      this.item.input.profileVersion
    )
  }

  /**
   * The header description for the item.
   */
  @computed
  get headerDescription() {
    return [
      this.profile.personalInfo.dateOfBirth,
      obscureSsn(
        this.profile.personalInfo.personalTaxpayerIdentificationNumber
      ),
    ]
      .filter((line) => Boolean(line))
      .join(' / ')
  }

  /**
   * Shows the spousal information dialog.
   */
  @action
  showDialog() {
    this.showingDialog = true
  }

  /**
   * Closes the spousal information dialog.
   */
  @action
  closeDialog() {
    this.showingDialog = false
  }

  /**
   * Fetches the spousal profile for the job.
   */
  @task
  async fetchSpousalProfile() {
    if (!this.item.input?.profileId) {
      return
    }

    await this.individualProfileStore
      .fetchSpousalProfileForJob(this.currentJob.id)
      .then(() => this.fillProfile())
      .catch((e) => {
        this.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })
  }

  /**
   * Submits the spousal information.
   *
   * @returns
   */
  @task
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    try {
      const result = await this.individualProfileStore.saveSpousalProfile(
        this.currentJob.workspaceId,
        this.personalInfo.toDto(),
        this.occupation
      )

      await this.infoGatheringStore.provideSpousalInfo(
        this.currentJob.id,
        this.item,
        result.id,
        result.version
      )
      this.closeDialog()
    } catch (ex) {
      this.flashMessageStore.create({
        type: 'error',
        message: extractMessageFromError(ex),
      })
    }
  }

  /**
   * Assigns the fetched profile to the view model.
   */
  @action.bound
  fillProfile() {
    if (!this.profile) {
      return
    }

    this.personalInfo.parse(this.profile.personalInfo)
    this.occupation = this.profile.occupation
  }
}
