import { reaction, observable, action, computed } from 'mobx'
import { isBefore, isSameDay, addDays, addMinutes, format, set } from 'date-fns'
import TimeRange from '@taxfyle/web-commons/lib/utils/TimeRange'
import { task } from 'mobx-task'
import { Store } from 'libx'
import moment from 'moment-timezone'
import { getFeatureToggleClient } from 'misc/featureToggles'

export default class ScheduleStore extends Store {
  daysShown = 7
  intervalInMinutes = 60

  @observable
  timeSlots = []

  @observable
  scheduledDays = []

  @observable
  dayIndex = 0

  constructor() {
    super(...arguments)
    this.reset()
    reaction(
      () => this.consultation,
      () => this.populateScheduledDays()
    )
  }

  @computed
  get selectedDay() {
    return this.scheduledDays[this.dayIndex]
  }

  @computed
  get selectedTimeRanges() {
    return this.scheduledDays.flatMap((sd) => sd.timeSlotsConsolidated)
  }

  @computed
  get hasSelectedTimes() {
    return this.selectedTimeRanges.length > 0
  }

  @computed
  get scheduledAvailability() {
    return this.scheduledDays.filter((d) => d.timeSlots.length > 0)
  }

  @computed
  get consultation() {
    return this.rootStore.projectDetailsStore?.project?.consultation
  }

  @computed
  get skipSchedulingAndActive() {
    return (
      this.consultation?.skipScheduling &&
      this.rootStore.projectDetailsStore?.project?.status === 'CLAIMED'
    )
  }

  @computed
  get hasDiyForCurrentYear() {
    return this.rootStore.projectDetailsStore?.hasDiyForCurrentYear
  }

  @computed
  get bookingInformation() {
    const bookedTime = this.consultation?.bookedTime
    const phone = this.rootStore.projectDetailsStore?.formattedPhone

    if (this.consultation.skipScheduling) {
      if (this.consultation.status === 'DONE') {
        return ''
      }

      if (!this.isPhoneCall) {
        return `Message your Pro to schedule a consultation call.`
      }

      const phoneMessage = phone
        ? `, using phone number: ${phone}`
        : `. We currently don't have your number on file, update your number below before starting the call`

      return `Message your Pro to schedule a consultation call${phoneMessage}`
    }

    if (!bookedTime) {
      return `We have shared your updated availability with your Pro! As soon as
      they select a new date and time, we will notify you.`
    }
    const date = format(bookedTime.startTime, 'EEEE, MMMM do')
    const startTime = format(bookedTime.startTime, 'h:mm')
    const endTime = format(bookedTime.endTime, 'h:mm a')
    const timeZone = moment.tz(moment.tz.guess()).zoneAbbr()

    if (this.consultation.status === 'DONE') {
      return `Call took place on ${date} at ${startTime} - ${endTime} ${timeZone}.`
    }

    return `Call scheduled for ${date} at ${startTime} - ${endTime} ${timeZone} ${
      this.isPhoneCall ? `using phone number: ${phone}.` : '.'
    }`
  }

  // Determine whether we are using voice or video.
  // This is currently a feature but could be a whole protocol
  // If we want to support both.
  get isPhoneCall() {
    return !getFeatureToggleClient().variation('Portals.CallsV3', false)
  }

  @action.bound
  async activate() {
    this.reset()
  }

  /**
   * Sets up the next 5 days and Populates the user's previously selected availability.
   */
  @action.bound
  populateScheduledDays() {
    this.scheduledDays = []
    this.intervalInMinutes = this.consultation?.requestedLength || 60
    const currentDate = new Date()
    const nextHour = addMinutes(currentDate, 60).getHours()
    const cutoffHour = 19 // cuts off scheduling for the current day at 7PM

    const startDate = this.hasDiyForCurrentYear
      ? nextHour >= cutoffHour
        ? addDays(currentDate, 1)
        : currentDate
      : addDays(currentDate, 1)
    const prevAvailability = this.consultation?.availability?.flatMap((a) =>
      a.decomposeRangeInIntervals(this.intervalInMinutes)
    )

    let date = startDate
    while (this.scheduledDays.length < this.daysShown) {
      const sd = new ScheduledDay(date)
      prevAvailability?.forEach((a) => {
        if (isSameDay(date, a.startTime)) {
          sd.selectTimeSlot(a)
        }
      })

      this.scheduledDays.push(sd)
      date = addDays(date, 1)
    }

    this.populateTimeSlotsForFirstDay()
  }

  /**
   * Generates time slots for the UI.
   *
   * @param {*} startDate
   */
  @action.bound
  populateTimeSlots(startDate, startingHour, startingMinute) {
    const from = set(startDate, {
      hours: startingHour,
      minutes: startingMinute,
      seconds: 0,
      milliseconds: 0,
    })

    const to = set(startDate, {
      hours: 21,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })

    this.timeSlots = []
    let cursor = from
    while (isBefore(cursor, to)) {
      const end = addMinutes(cursor, this.intervalInMinutes)
      this.timeSlots.push(new TimeRange(cursor, end))
      cursor = end
    }
  }

  /**
   * Get the next available day.
   */
  @action.bound
  nextDay() {
    this.dayIndex += 1
    this.dayIndex %= this.daysShown
  }

  /**
   * Get the previous available day.
   */
  @action.bound
  prevDay() {
    this.dayIndex += -1 + this.daysShown
    this.dayIndex %= this.daysShown
  }

  /**
   * Sets the selected date.
   *
   * @param {*} index
   */
  @action.bound
  setDay(index) {
    this.dayIndex = index

    if (index !== 0) {
      this.populateTimeSlots(this.selectedDay.day, 8, 0)
    } else {
      this.populateTimeSlotsForFirstDay()
    }
  }

  /**
   * Reloads the scheduled days list.
   */
  @action.bound
  reset() {
    this.dayIndex = 0
    // Set up the next 5 days excluding weekends.
    this.populateScheduledDays()
  }

  @task
  async setConsultationAvailability() {
    await this.rootStore.projectDetailsStore.setConsultationAvailability(
      this.selectedTimeRanges
    )
  }

  @action
  populateTimeSlotsForFirstDay() {
    const currentDate = this.selectedDay.day
    const nextHour = addMinutes(currentDate, 60).getHours()
    const cutoffHour = 19 // cuts off scheduling for the current day at 7PM
    /**
     * If we have DIY, start from the next reasonable hour.
     * Otherwise, start at 8 AM
     */
    const startingHour = this.hasDiyForCurrentYear
      ? nextHour >= cutoffHour
        ? 8
        : nextHour
      : 8

    /**
     * If we have DIY, start at the next reasonable 30 minute interval.
     * Otherwise, start at 0 minutes.
     */
    const startingMinute = this.hasDiyForCurrentYear
      ? currentDate.getMinutes() >= 30
        ? 30
        : 0
      : 0
    const firstTimeSlot =
      this.selectedDay.timeSlots.length > 0
        ? this.selectedDay.timeSlots[0]
        : null

    if (
      firstTimeSlot &&
      isBefore(
        firstTimeSlot.startTime,
        set(currentDate, {
          hours: startingHour,
          minutes: startingMinute,
          seconds: 0,
          milliseconds: 0,
        })
      )
    ) {
      this.populateTimeSlots(
        currentDate,
        firstTimeSlot.startTime.getHours(),
        firstTimeSlot.startTime.getMinutes()
      )
      return
    }

    this.populateTimeSlots(currentDate, startingHour, startingMinute)
  }
}

/**
 * A day which maintains a list of time slots.
 */
class ScheduledDay {
  @observable
  day

  @observable
  timeSlots = []

  constructor(day) {
    this.day = day
  }

  @computed
  get timeSlotsConsolidated() {
    const sorted = this.timeSlots
      .slice()
      .sort((a, b) => a.startTime.getTime() - b.startTime.getTime())

    return sorted.reduce((accum, curr) => {
      if (accum.length === 0) {
        return [curr]
      }

      const prev = accum.pop()
      // Current range is inside the previous
      if (curr.endTime <= prev.endTime) {
        return [...accum, prev]
      }

      // Ranges are overlapping or continuous
      if (curr.startTime <= prev.endTime) {
        return [...accum, new TimeRange(prev.startTime, curr.endTime)]
      }

      // Nothing overlapping, move along
      return [...accum, prev, curr]
    }, [])
  }

  /**
   * Adds a time slot to be saved.
   *
   * @param {*} slot
   */
  @action
  selectTimeSlot(slot) {
    // You can't select a date that has passed
    if (isBefore(slot.startTime, new Date())) {
      return
    }

    const existingTime = this.timeSlots.findIndex((t) => t.isEqual(slot))
    if (existingTime > -1) {
      this.timeSlots.splice(existingTime, 1)
    } else {
      // Ensure the saved time slot has the correct day
      this.timeSlots.push(this.timeRangeForCurrentDay(slot))
    }
  }

  /**
   * Creates a TimeRange from a time slot for the current day.
   *
   * @param {*} slot
   * @returns
   */
  timeRangeForCurrentDay(slot) {
    return new TimeRange(
      set(new Date(this.day), {
        hours: slot.startTime.getHours(),
        minutes: slot.startTime.getMinutes(),
      }),
      set(new Date(this.day), {
        hours: slot.endTime.getHours(),
        minutes: slot.endTime.getMinutes(),
      })
    )
  }
}
